import { IonText } from "@ionic/react";
import "./ExploreContainer.css";
function getRelativeTimeSinceTimeStamp(timestamp: number) {
  // Calculate the difference between the given timestamp and the current time
  const timeDiff = Date.now() - timestamp;

  // Convert the time difference to seconds
  const timeDiffInSeconds = timeDiff / 1000;

  // Use the appropriate time unit and value for the time difference
  if (timeDiffInSeconds < 60) {
    return `just now`;
  }
  if (timeDiffInSeconds < 3600) {
    return `${Math.round(timeDiffInSeconds / 60)}m`;
  }
  if (timeDiffInSeconds < 86400) {
    return `${Math.round(timeDiffInSeconds / 3600)}h`;
  }
  if (timeDiffInSeconds < 604800) {
    return `${Math.round(timeDiffInSeconds / 86400)}d`;
  }
  if (timeDiffInSeconds < 2629746) {
    return `${Math.round(timeDiffInSeconds / 604800)}w`;
  }
}
interface Props {
  timestamp: number;
}

const TimeSincePosted: React.FC<Props> = ({ timestamp }) => {
  return <IonText>{getRelativeTimeSinceTimeStamp(timestamp)}</IonText>;
};

export default TimeSincePosted;
